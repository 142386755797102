import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import acalvio from "../images/acalvio-cybersecurity.svg"
import Shapefour from "../images/bannerElement/white-design-element-cybersecurity.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import creativeContent from "../images/creative-content.svg"
import AlignSalesandMarketing from "../images/icons/Cybersecurity/AlignSalesandMarketing.svg"
import ExpandMarketreach from "../images/icons/Cybersecurity/ExpandMarketreach.svg"
import RefinedBuyerPersonas from "../images/icons/Cybersecurity/RefinedBuyerPersonas.svg"
import StandOutfromCompetitors from "../images/icons/Cybersecurity/StandOutfromCompetitors.svg"
import mandiant from "../images/mandiant-cybersecurity.svg"
import marketingAutomation from "../images/marketing-automation.svg"
import paidMarketing from "../images/paid-marketing.svg"
import seo from "../images/seo.svg"
import thales from "../images/thales-cybersecurity.svg"
import webDevelopment from "../images/web-development.svg"
import "../styles/sass.css"

export default function Fintech() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Cyberecurity | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div className="sassStyles">
          <section id="Banner" className="cybersecurity">
            <div className="container">
              <div className="banner-holder">
                <div className="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div className="title">
                  <div className="banner-title">
                    <h1>Generate more cybersecurity leads</h1>
                    <p>
                      Attract new clients and showcase your expertise with our
                      proven strategies
                    </p>
                    <a
                      className="button"
                      id="3dsolutionvideo"
                      href="/contact-us/"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div className="container">
              <h2>Conquer the cybersecurity landscape</h2>
              <p>
                In today's ever-evolving cybersecurity landscape, standing out
                from the crowd and achieving sustainable growth requires a
                strategic and data-driven approach. We understand the unique
                challenges faced by cybersecurity leaders and that's why we
                challenges faced by cybersecurity leaders and that's why we
                deliver customizable marketing programs designed to attract
                high-value customers, outpace your competitors, and expand your
                market share.
              </p>
            </div>
          </section>
          <section id="WeDeliver">
            <div className="container">
              <h2>We are your partner for sustainable results</h2>
              <p>
                We delve into comprehending your goals, technical stack,
                processes, and ideal buyer personas. We devise holistic
                strategies to engage security-focused decision-makers.
              </p>
              <p>
                <strong>
                  Our battle-tested solutions will directly address the distinct
                  challenges you face:
                </strong>
              </p>
              <div className="horizontalImageIconSec">
                <div className="moduleWrap">
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={RefinedBuyerPersonas}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Refined Buyer Personas</h3>
                      <p>
                        We dig into your audience data to identify and target
                        key decision-makers.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={AlignSalesandMarketing}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Align Sales and Marketing</h3>
                      <p>
                        We bridge teams through coordinated strategies and
                        shared insights.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={StandOutfromCompetitors}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Stand Out from the Competitors</h3>
                      <p>
                        Our strategic messaging and positioning highlight your
                        unique value.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img src={ExpandMarketreach} width={100} height={100} />
                    </div>
                    <div className="contents">
                      <h3>Expand Market Reach</h3>
                      <p>
                        Multi-channel campaigns extend your presence across
                        digital touchpoints.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="IndustryLeaders">
            <div className="container">
              <div className="innerWrap">
                <h2 className="title">Proven for industry leaders</h2>
                <div className="logos">
                  <img src={mandiant} />
                  <img src={acalvio} />
                  <img src={thales} />
                </div>
              </div>
            </div>
          </section>
          <section id="OurServices">
            <div className="container">
              <h2>Our end-to-end services</h2>
              <div className="tilesWrap">
                <a className="eachtiles" href="/web-design-and-development/">
                  <img src={webDevelopment} />
                  <span>Website Development</span>
                </a>
                <a className="eachtiles" href="/creative-assets/">
                  <img src={creativeContent} />
                  <span>Creative & Content</span>
                </a>
                <a className="eachtiles" href="/paid-marketing/">
                  <img src={paidMarketing} />
                  <span>Paid Advertising</span>
                </a>
                <a className="eachtiles" href="/seo/">
                  <img src={seo} />
                  <span>SEO</span>
                </a>
                <a className="eachtiles" href="/marketing-automation/">
                  <img src={marketingAutomation} />
                  <span>Marketing Automation</span>
                </a>
              </div>
              <h2>Fortify your cybersecurity brand, outmaneuver threats</h2>
              <p>
                Our tailored strategies merge industry insights and cutting-edge
                tactics, ensuring you stay ahead of the curve while we amplify
                your market presence.
              </p>
            </div>
          </section>
          <section id="contactus-section" className="cybersecurity">
            <div className="container">
              <h2>Ready to accelerate growth?</h2>
              <a className="button" id="3dsolutionvideo" href="/contact-us/">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}
